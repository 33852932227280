<template>
    <div>
        <b-container>
            <section class="profile">
                <b-row>
                    <b-col cols="12">
                        <div class="sector pl-0 pr-0">
                            <h2 class="profile__title mb-4">Add new agency</h2>
                            <div class="profile__content">
                                <p v-if="addAgencyForm.localError" class="custom-error mb-3">{{ addAgencyForm.localError }}</p>
                                <p v-if="getAdminAgenciesError" class="custom-error mb-3"> {{ getAdminAgenciesError }} </p>
                                <form :class="getDisabledAdminAgency ? 'disabled-element' : ''" @submit.prevent="addAdminAgency" autocomplete="off">
                                    <b-row>
                                        <b-col cols="12">
                                            <span class="profile__label float-right">*Required fields</span>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Company name*</label>
                                                <input :disabled="getDisabledAdminAgency" v-model.trim="addAgencyForm.agencyName" @blur="$v.addAgencyForm.agencyName.$touch()" :class="[{'input-error' : $v.addAgencyForm.agencyName.$error }, getDisabledAdminAgency ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type company name" autocomplete="off" />
                                                <div v-if="$v.addAgencyForm.agencyName.$error">
                                                    <p v-if="!$v.addAgencyForm.agencyName.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addAgencyForm.agencyName.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Employees number*</label>
                                                <v-select :disabled="getDisabledAdminAgency" @blur="$v.addAgencyForm.agencyEmployees.$touch()" :class="[{'input-error' : $v.addAgencyForm.agencyEmployees.$error }, getDisabledAdminAgency ? 'disabled-element' : '' ]" v-model.trim="addAgencyForm.agencyEmployees" class="custom-sorter mb-1" labelTitle = "Select employees number" :options="employees" />
                                                <div v-if="$v.addAgencyForm.agencyEmployees.$error">
                                                    <p v-if="!$v.addAgencyForm.agencyEmployees.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addAgencyForm.agencyEmployees.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">E-mail address</label>
                                                <input :disabled="getDisabledAdminAgency" v-model.trim="addAgencyForm.agencyEmail" @blur="$v.addAgencyForm.agencyEmail.$touch()" :class="[{'input-error' : $v.addAgencyForm.agencyEmail.$error }, getDisabledAdminAgency ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type company e-mail" autocomplete="off" type="email" />
                                                <div v-if="$v.addAgencyForm.agencyEmail.$error">
                                                    <p v-if="!$v.addAgencyForm.agencyEmail.email" class="custom-error">E-mail address is invalid</p>
                                                    <p v-else-if="!$v.addAgencyForm.agencyEmail.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Company website URL*<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />With http or https protocol</span></label>
                                                <input :disabled="getDisabledAdminAgency" v-model.trim="addAgencyForm.agencyUrl" @blur="$v.addAgencyForm.agencyUrl.$touch()" :class="[{'input-error' : $v.addAgencyForm.agencyUrl.$error }, getDisabledAdminAgency ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type company website URL" autocomplete="off" />
                                                <div v-if="$v.addAgencyForm.agencyUrl.$error">
                                                    <p v-if="!$v.addAgencyForm.agencyUrl.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addAgencyForm.agencyUrl.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                    <p v-else-if="!$v.addAgencyForm.agencyUrl.urlCustomValidator" class="custom-error">This field must start with either http:// or https://</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">City*</label>
                                                <input :disabled="getDisabledAdminAgency" v-model.trim="addAgencyForm.agencyCity" @blur="$v.addAgencyForm.agencyCity.$touch()" :class="[{'input-error' : $v.addAgencyForm.agencyCity.$error }, getDisabledAdminAgency ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type city" autocomplete="off" />
                                                <div v-if="$v.addAgencyForm.agencyCity.$error">
                                                    <p v-if="!$v.addAgencyForm.agencyCity.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addAgencyForm.agencyCity.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Minimum project size*</label>
                                                <v-select :disabled="getDisabledAdminAgency" @blur="$v.addAgencyForm.agencyProject.$touch()" :class="[{'input-error' : $v.addAgencyForm.agencyProject.$error }, getDisabledAdminAgency ? 'disabled-element' : '' ]" v-model.trim="addAgencyForm.agencyProject" class="custom-sorter mb-1" labelTitle = "Select min project size" :options="projectSizes" />
                                                <div v-if="$v.addAgencyForm.agencyProject.$error">
                                                    <p v-if="!$v.addAgencyForm.agencyProject.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addAgencyForm.agencyProject.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Founding year*</label>
                                                <date-picker  :disabled="getDisabledAdminAgency" :class="[{'input-error' : $v.addAgencyForm.agencyFounded.$error }, getDisabledAdminAgency ? 'disabled-element' : '' ]" v-model.trim="addAgencyForm.agencyFounded" class="mb-1"  lang="en" type="year" format="YYYY" placeholder="Select founding year"></date-picker>
                                                <div v-if="$v.addAgencyForm.agencyFounded.$error">
                                                    <p v-if="!$v.addAgencyForm.agencyFounded.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addAgencyForm.agencyFounded.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Phone number</label>
                                                <input :disabled="getDisabledAdminAgency" v-model.trim="addAgencyForm.agencyPhone" @blur="$v.addAgencyForm.agencyPhone.$touch()" :class="[{'input-error' : $v.addAgencyForm.agencyPhone.$error }, getDisabledAdminAgency ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type company phone number" autocomplete="off" />
                                                <div v-if="$v.addAgencyForm.agencyPhone.$error">
                                                    <p v-if="!$v.addAgencyForm.agencyPhone.maxLength" class="custom-error">This field must not exceed 20 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Company address*<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />Street name and street number</span></label>
                                                <input :disabled="getDisabledAdminAgency" v-model.trim="addAgencyForm.agencyAddress" @blur="$v.addAgencyForm.agencyAddress.$touch()" :class="[{'input-error' : $v.addAgencyForm.agencyAddress.$error }, getDisabledAdminAgency ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type company address" autocomplete="off" />
                                                <div v-if="$v.addAgencyForm.agencyAddress.$error">
                                                    <p v-if="!$v.addAgencyForm.agencyAddress.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addAgencyForm.agencyAddress.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Country*</label>
                                                <v-select :disabled="getDisabledAdminAgency" @blur="$v.addAgencyForm.agencyCountry.$touch()" :class="[{'input-error' : $v.addAgencyForm.agencyCountry.$error }, getDisabledAdminAgency ? 'disabled-element' : '' ]" v-model.trim="addAgencyForm.agencyCountry" class="custom-sorter mb-1" labelTitle = "Select country" :options="countries" :searchable = true />
                                                <div v-if="$v.addAgencyForm.agencyCountry.$error">
                                                    <p v-if="!$v.addAgencyForm.agencyCountry.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addAgencyForm.agencyCountry.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <div class="profile__element">
                                                <label class="profile__label">Company description*</label>
                                                <vue-editor :disabled="getDisabledAdminAgency" v-model.trim="addAgencyForm.agencyDescription" @blur="$v.addAgencyForm.agencyDescription.$touch()" :class="[{'input-error' : $v.addAgencyForm.agencyDescription.$error }, getDisabledAdminAgency ? 'disabled-element' : '' ]" :editorToolbar="customToolbar"></vue-editor>
                                                <div v-if="$v.addAgencyForm.agencyDescription.$error">
                                                    <p v-if="!$v.addAgencyForm.agencyDescription.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addAgencyForm.agencyDescription.maxLength" class="custom-error">This field must not exceed 3000 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Company services*<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />Tick services that your company provides</span></label><br>
                                                <p-check v-model="addAgencyForm.advertising" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Advertising
                                                </p-check>
                                                <p-check v-model="addAgencyForm.digital" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Digital marketing
                                                </p-check>
                                                <p-check v-model="addAgencyForm.branding" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Branding
                                                </p-check>
                                                <p-check v-model="addAgencyForm.social" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Social media marketing
                                                </p-check>
                                                <p-check v-model="addAgencyForm.webDev" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Web development
                                                </p-check>
                                                <p-check v-model="addAgencyForm.mobile" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Mobile app development
                                                </p-check>
                                                <p-check v-model="addAgencyForm.commerce" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>eCommerce
                                                </p-check>
                                                <p-check v-model="addAgencyForm.data" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Big Data
                                                </p-check>
                                                <p-check v-model="addAgencyForm.emailMarketing" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>E-mail marketing
                                                </p-check>
                                                <p-check v-model="addAgencyForm.eventMarketing" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Event marketing
                                                </p-check>
                                                <p-check v-model="addAgencyForm.cloud" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Cloud solutions
                                                </p-check>
                                                <p-check v-model="addAgencyForm.seo" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>SEO
                                                </p-check>
                                                <p-check v-model="addAgencyForm.graphic" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Graphic design
                                                </p-check>
                                                <p-check v-model="addAgencyForm.print" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Print design
                                                </p-check>
                                                <p-check v-model="addAgencyForm.video" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Video production
                                                </p-check>
                                                <div>
                                                    <p v-if="addAgencyForm.servicesError" class="custom-error mb-3">{{ addAgencyForm.servicesError }}</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Location* <span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />Move the blue marker on the map and specify the location</span></label>
                                                <div class="profile__map">
                                                    <l-map :zoom="zoom" :center="center" :options="{zoomControl: false}">
                                                        <l-tile-layer
                                                        :url="url"
                                                        layer-type="base"></l-tile-layer>
                                                        <l-marker :lat-lng="addAgencyForm.agencyMarker" :draggable="true" @update:latLng="positionUpdate"></l-marker>
                                                        <l-control-zoom position="topright"></l-control-zoom>
                                                    </l-map>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Latitude</label>
                                                <input readonly :disabled="getDisabledAdminAgency" @blur="$v.addAgencyForm.agencyMarker.lat.$touch()" :class="[{'input-error' : $v.addAgencyForm.agencyMarker.lat.$error }, getDisabledAdminAgency ? 'disabled-element' : '' ]" v-model="addAgencyForm.agencyMarker.lat" class="form-input mb-1" />
                                                <div v-if="$v.addAgencyForm.agencyMarker.lat.$error">
                                                    <p v-if="!$v.addAgencyForm.agencyMarker.lat.required" class="custom-error">This field is required</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Longitude</label>
                                                <input readonly :disabled="getDisabledAdminAgency" @blur="$v.addAgencyForm.agencyMarker.lat.$touch()" :class="[{'input-error' : $v.addAgencyForm.agencyMarker.lat.$error }, getDisabledAdminAgency ? 'disabled-element' : '' ]" v-model="addAgencyForm.agencyMarker.lat" class="form-input mb-1" />
                                                <div v-if="$v.addAgencyForm.agencyMarker.lat.$error">
                                                    <p v-if="!$v.addAgencyForm.agencyMarker.lat.required" class="custom-error">This field is required</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <div class="profile__element">
                                                <label class="profile__label">Company logo*<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />Recommended size: 150px by 150px with transparent background, JPG, PNG, max 5MB</span></label>
                                                <b-row>
                                                    <b-col cols="12">
                                                        <div v-if="addAgencyForm.selectedFile" id="agencyLogo" class="profile__logo-image"></div>
                                                    </b-col>
                                                    <b-col cols="12">
                                                        <div class="custom-upload mt-3">
                                                            <label for="fileUpload" class="custom-upload-label">
                                                                <simple-line-icons icon="picture" size="small" color="#504e70" />Choose file
                                                            </label>
                                                            <input :class="getDisabledAdminAgency ? 'disabled-element' : ''" @change="onFileChanged" id="fileUpload" type="file"/>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                                <div v-if="$v.addAgencyForm.selectedFile.$error">
                                                    <p v-if="!$v.addAgencyForm.selectedFile.required" class="custom-error">This field is required</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <button :disabled="getDisabledAdminAgency" type="submit" class="details-button details-button--red"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Save agency</button>
                                        </b-col>
                                    </b-row>
                                </form>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import VSelect from '../assets/files/customSelect.esm.js'
import PrettyCheck from 'pretty-checkbox-vue/check'
import DatePicker from 'vue2-datepicker'
import { VueEditor, Quill } from 'vue2-editor'
import countriesList from '../assets/files/countriesList.js'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
import {
  email,
  required,
  maxLength
} from 'vuelidate/lib/validators'
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LIcon,
  LControlZoom
} from 'vue2-leaflet'

function urlCustomValidator (value) {
  var pattern = /^((http|https):\/\/)/

  if (!pattern.test(value)) {
    return false
  } else {
    return true
  }
}

export default {
  components: {
    SimpleLineIcons,
    VSelect,
    'p-check': PrettyCheck,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
    LControlZoom,
    DatePicker,
    VueEditor,
    Spinner
  },
  data: function () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 16,
      center: [40.73461490255838, -73.98722073063256],
      customToolbar: [
        ['bold', 'italic', 'underline', 'link'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean']
      ],
      projectSizes: [
        { value: '0', text: 'None' },
        { value: '1', text: '<$1000' },
        { value: '2', text: '$5000+' },
        { value: '3', text: '$10 000+' },
        { value: '4', text: '$25 000+' },
        { value: '5', text: '$50 000+' },
        { value: '6', text: '$75 000+' },
        { value: '7', text: '$100 000+' },
        { value: '8', text: '$200 000+' }
      ],
      employees: [
        { value: '0', text: '1-10' },
        { value: '1', text: '11-25' },
        { value: '2', text: '26-50' },
        { value: '3', text: '51-75' },
        { value: '4', text: '76-100' },
        { value: '5', text: '101-150' },
        { value: '6', text: '151-200' },
        { value: '7', text: '201-500' },
        { value: '8', text: '501+' }
      ],
      countries: [],
      addAgencyForm: {
        agencyName: null,
        agencyEmployees: null,
        localError: null,
        agencyEmail: '',
        agencyPhone: '',
        agencyUrl: '',
        agencyAddress: null,
        agencyCountry: null,
        agencyCity: null,
        agencyProject: null,
        agencyFounded: null,
        agencyDescription: null,
        advertising: false,
        digital: false,
        branding: false,
        social: false,
        webDev: false,
        mobile: false,
        commerce: false,
        data: false,
        emailMarketing: false,
        eventMarketing: false,
        cloud: false,
        seo: false,
        graphic: false,
        print: false,
        video: false,
        agencyMarker: {
          lat: 40.73461490255838, lng: -73.98722073063256
        },
        selectedFile: null,
        servicesError: null
      }
    }
  },
  validations: {
    addAgencyForm: {
      agencyName: {
        required: required,
        maxLength: maxLength(200)
      },
      agencyEmployees: {
        required: required,
        maxLength: maxLength(200)
      },
      agencyEmail: {
        email: email,
        maxLength: maxLength(200)
      },
      agencyUrl: {
        required: required,
        maxLength: maxLength(200),
        urlCustomValidator
      },
      agencyPhone: {
        maxLength: maxLength(20)
      },
      agencyAddress: {
        required: required,
        maxLength: maxLength(200)
      },
      agencyCountry: {
        required: required,
        maxLength: maxLength(200)
      },
      agencyCity: {
        required: required,
        maxLength: maxLength(200)
      },
      agencyProject: {
        required: required,
        maxLength: maxLength(200)
      },
      agencyFounded: {
        required: required,
        maxLength: maxLength(200)
      },
      agencyDescription: {
        required: required,
        maxLength: maxLength(3000)
      },
      agencyMarker: {
        lat: {
          required: required
        },
        lng: {
          required: required
        }
      },
      selectedFile: {
        required: required
      }
    }
  },
  methods: {
    positionUpdate (latLng) {
      this.addAgencyForm.agencyMarker = latLng
    },
    addAdminAgency () {
      this.$store.dispatch('setDisabledAdminAgency')
      this.$v.addAgencyForm.$touch()
      if (this.addAgencyForm.advertising == false && this.addAgencyForm.digital == false && this.addAgencyForm.branding == false && this.addAgencyForm.social == false && this.addAgencyForm.webDev == false && this.addAgencyForm.mobile == false && this.addAgencyForm.commerce == false && this.addAgencyForm.data == false && this.addAgencyForm.emailMarketing == false && this.addAgencyForm.eventMarketing == false && this.addAgencyForm.cloud == false && this.addAgencyForm.seo == false && this.addAgencyForm.graphic == false && this.addAgencyForm.print == false && this.addAgencyForm.video == false) {
        this.addAgencyForm.servicesError = 'At least one service has to be checked'
        this.scrollToTop(500)
        this.addAgencyForm.localError = 'Invalid form'
        this.$store.dispatch('clearDisabledAdminAgency')
      } else {
        this.addAgencyForm.servicesError = null
        if (!this.$v.addAgencyForm.$invalid) {
          this.$store.dispatch('addAdminAgency', { name: this.addAgencyForm.agencyName, employees: this.addAgencyForm.agencyEmployees, email: this.addAgencyForm.agencyEmail, website: this.addAgencyForm.agencyUrl, phone: this.addAgencyForm.agencyPhone, address: this.addAgencyForm.agencyAddress, country: this.addAgencyForm.agencyCountry, city: this.addAgencyForm.agencyCity, project: this.addAgencyForm.agencyProject, founded: this.addAgencyForm.agencyFounded, desc: this.addAgencyForm.agencyDescription, advertising: this.addAgencyForm.advertising, digital: this.addAgencyForm.digital, branding: this.addAgencyForm.branding, social: this.addAgencyForm.social, webDev: this.addAgencyForm.webDev, mobile: this.addAgencyForm.mobile, commerce: this.addAgencyForm.commerce, data: this.addAgencyForm.data, emailMarketing: this.addAgencyForm.emailMarketing, eventMarketing: this.addAgencyForm.eventMarketing, cloud: this.addAgencyForm.cloud, seo: this.addAgencyForm.seo, graphic: this.addAgencyForm.graphic, print: this.addAgencyForm.print, video: this.addAgencyForm.video, lat: this.addAgencyForm.agencyMarker.lat, lng: this.addAgencyForm.agencyMarker.lng, image: this.addAgencyForm.selectedFile, imageName: this.getGuid() })
            .then(() => {
              this.addAgencyForm.localError = ''
              this.scrollToTop(500)
            })
            .catch(error => {
              this.scrollToTop(500)
              this.addAgencyForm.localError = error.message
              this.$store.dispatch('clearDisabledAdminAgency')
            })
        } else {
          this.scrollToTop(500)
          this.addAgencyForm.localError = 'Invalid form'
          this.$store.dispatch('clearDisabledAdminAgency')
        }
      }
    },
    scrollToTop (scrollDuration) {
      var scrollStep = -window.scrollY / (scrollDuration / 15)
      var scrollInterval = setInterval(function () {
        if (window.scrollY != 0) {
          window.scrollBy(0, scrollStep)
        } else clearInterval(scrollInterval)
      }, 15)
    },
    onFileChanged (event) {
      if (event.target.files[0] != undefined) {
        this.addAgencyForm.selectedFile = event.target.files[0]

        var inputFile = document.querySelector('#fileUpload')

        var selectedValue = document.querySelector('#fileUpload').value

        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i

        var fileSize = this.addAgencyForm.selectedFile.size / 1024 / 1024 // in MB

        if (!allowedExtensions.exec(selectedValue)) {
          this.$swal({
            type: 'error',
            title: 'Invalid file extension',
            text: 'Supported file formats: .jpeg .jpg .png .gif',
            confirmButtonText: 'OK'
          })

          inputFile.value = ''

          this.addAgencyForm.selectedFile = null

          return false
        } else if (fileSize > 5) {
          this.$swal({
            type: 'error',
            title: 'File size is too large',
            text: 'Uploaded file size must not exceed 5MB',
            confirmButtonText: 'OK'
          })

          inputFile.value = ''

          this.addAgencyForm.selectedFile = null

          return false
        } else {
          // Image preview
          if (this.addAgencyForm.selectedFile) {
            var reader = new FileReader()
            reader.onload = function (e) {
              document.getElementById('agencyLogo').style.backgroundImage = 'url("' + e.target.result + '")'
            }
            reader.readAsDataURL(inputFile.files[0])
          }
        }
      }
    },
    getGuid () {
      function s4 () {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1)
      }
      return (
        s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
      )
    }
  },
  beforeCreate () {
    this.$store.dispatch('clearError')
    this.$store.dispatch('clearDisabledAdminAgency')
  },
  created () {
    this.countries = countriesList

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = [position.coords.latitude, position.coords.longitude]
        this.addAgencyForm.agencyMarker.lat = position.coords.latitude
        this.addAgencyForm.agencyMarker.lng = position.coords.longitude
      })
    }
  },
  computed: {
    ...mapGetters([
      'getAdminAgenciesError',
      'getDisabledAdminAgency'
    ])
  },
  watch: {
    'addAgencyForm.advertising': function () {
      if (this.addAgencyForm.advertising == true) {
        this.addAgencyForm.servicesError = null
      }
    },
    'addAgencyForm.digital': function () {
      if (this.addAgencyForm.digital == true) {
        this.addAgencyForm.servicesError = null
      }
    },
    'addAgencyForm.branding': function () {
      if (this.addAgencyForm.branding == true) {
        this.addAgencyForm.servicesError = null
      }
    },
    'addAgencyForm.social': function () {
      if (this.addAgencyForm.social == true) {
        this.addAgencyForm.servicesError = null
      }
    },
    'addAgencyForm.webDev': function () {
      if (this.addAgencyForm.webDev == true) {
        this.addAgencyForm.servicesError = null
      }
    },
    'addAgencyForm.mobile': function () {
      if (this.addAgencyForm.mobile == true) {
        this.addAgencyForm.servicesError = null
      }
    },
    'addAgencyForm.commerce': function () {
      if (this.addAgencyForm.commerce == true) {
        this.addAgencyForm.servicesError = null
      }
    },
    'addAgencyForm.data': function () {
      if (this.addAgencyForm.data == true) {
        this.addAgencyForm.servicesError = null
      }
    },
    'addAgencyForm.emailMarketing': function () {
      if (this.addAgencyForm.emailMarketing == true) {
        this.addAgencyForm.servicesError = null
      }
    },
    'addAgencyForm.eventMarketing': function () {
      if (this.addAgencyForm.eventMarketing == true) {
        this.addAgencyForm.servicesError = null
      }
    },
    'addAgencyForm.cloud': function () {
      if (this.addAgencyForm.cloud == true) {
        this.addAgencyForm.servicesError = null
      }
    },
    'addAgencyForm.seo': function () {
      if (this.addAgencyForm.seo == true) {
        this.addAgencyForm.servicesError = null
      }
    },
    'addAgencyForm.graphic': function () {
      if (this.addAgencyForm.graphic == true) {
        this.addAgencyForm.servicesError = null
      }
    },
    'addAgencyForm.print': function () {
      if (this.addAgencyForm.print == true) {
        this.addAgencyForm.servicesError = null
      }
    },
    'addAgencyForm.video': function () {
      if (this.addAgencyForm.video == true) {
        this.addAgencyForm.servicesError = null
      }
    }
  }
}
</script>
